<template>
  <div>
    <a
      v-if="!isAuth"
      :href="loginUrl"
    >Login</a>
    <h2 v-else>
      Logged In
    </h2>
  </div>
</template>

<script>
export default {
  asyncData ({ isDev, route, store, env, params, query, req, res, redirect, error }) {
    const domainApi = env.DRUPAL_AUTH_URL || 'https://dev-decoupler.pantheonsite.io/saml/login'
    return {
      isAuth: params.auth,
      loginUrl: `${domainApi}?l=${process.client ? window.location.href : ''}`
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
